import {
  AbstractMcpDeviceService,
  McpActiveProcessService,
  // McpDeviceMockService,
  McpDeviceService
} from './services';
import { ErrorHandlingOverlayComponent, HeaderComponent } from './components';

import { ConnectedGuard } from '@core/guards';
import { NgModule } from '@angular/core';
import { NotificationModule } from '@shared/components/notification/notification.module';
import { Router } from '@angular/router';
import { TranslocoRootModule } from '@core/transloco-root.module';
import { McpDeviceCommService } from './services/mcp-device-comm.service';
import { BroadcastService } from './services/broadcast.service';

const declarations = [HeaderComponent, ErrorHandlingOverlayComponent];
const providers = [
  ConnectedGuard,
  Router,
  // -- DEVELOPER MOCKED SERVICE --
  /*
  {
    provide: AbstractMcpDeviceService,
    useFactory: (router: Router) => new McpDeviceMockService(router),
    deps: [Router]
  },
  */
  // -- REAL DATA SERVICE --
  McpDeviceCommService,
  {
    provide: AbstractMcpDeviceService,
    useFactory: (
      router: Router,
      comm: McpDeviceCommService,
      broadcast: BroadcastService
    ) => new McpDeviceService(router, comm, broadcast),
    deps: [Router, McpDeviceCommService, BroadcastService]
  },
  McpActiveProcessService
];
const exports = [...declarations, TranslocoRootModule];
const imports = [NotificationModule];

@NgModule({
  declarations,
  providers,
  exports,
  imports
})
export class CoreModule {}
