import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { catchError, firstValueFrom } from "rxjs";
import { McpDeviceError } from "./abstract-mcp-device.service";

export interface RestResponse {
  code: string;
  error: string;
  message: string;
  payload: Record<string, any>;
}

export interface RemoteVersion {
  version: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestService {
  static readonly CURRENT_APP_SETTINGS_URL = 'https://maveoproadmin-test.azurewebsites.net/api/stored-jsons/get-mcp-app-settings';
  static readonly APP_SETTINGS_ID = 'mcp_settings';
  static readonly REST_API_KEY = 'Xnd0JiFcbURjdHhTIDQe3MgI';
  static readonly REST_CLIENT_ID = 'maveoserviceapp';

  constructor(private readonly _http: HttpClient) {}

  async getCurrentAppVersion() {
    try {
      const settings = await this.post(RestService.CURRENT_APP_SETTINGS_URL) as RemoteVersion;
      return settings.version;
    } catch (error) {
      throw new Error(error as unknown as string);
    }
  }

  async post(url: string, body?:any) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json; charset=UTF-8')
    .set('x-api-key', RestService.REST_API_KEY)
    .set('x-client-id',RestService.REST_CLIENT_ID);
    try {
      const result: RestResponse = await firstValueFrom(this._http.post(url, body, { headers })) as unknown as RestResponse;
      console.log('result: ', result);
      if (result?.error === '200') {
        if (result.payload['json']) {
          const jsonString: string = result.payload['json'];
          const json = JSON.parse(jsonString) as unknown;
          return json;
        } else {
          return result.payload;
        }
      } else {
        throw new Error('Server error: ' + result ? result.error : 'unknown error');
      }
    } catch (error) {
      console.log('[RestService] post - error: ', error);
      throw new Error('Error: ' + JSON.stringify(error));
    }
  }
}
