import { Language, MenuItem } from './shared/interfaces/interfaces';
import { MAIN_ROUTES, ROUTES } from './app-routing.config';
import { AF_ROUTES } from './features/main/features/additional-functions/additional-functions-routing.config';
import { GS_ROUTES } from './features/main/features/general-settings/general-settings-routing.config';
import { RS_ROUTES } from './features/main/features/radio-settings/radio-settings-routing.config';

export const MENU_ITEMS: MenuItem[] = [
  {
    label: 'main.list.radioSettings.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.RADIO_SETTINGS, RS_ROUTES.OVERVIEW]
  },
  {
    label: 'main.list.generalSettings.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.GENERAL_SETTINGS, GS_ROUTES.OVERVIEW]
  },
  {
    label: 'main.list.information.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.INFO]
  },
  {
    label: 'main.list.maintenance.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.MAINTENANCE]
  },
  {
    label: 'main.list.additionalFunctions.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.ADDITIONAL_FUNCTIONS, AF_ROUTES.OVERVIEW]
  },
  {
    label: 'main.list.errorStatus.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.ERROR_STATUSES]
  },
  {
    label: 'main.list.errorLog.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.ERROR_LOG]
  },
  {
    label: 'main.list.installation.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.POSITION_SETUP]
  },
  {
    label: 'main.list.asraMeasurements.title',
    disabled: true,
    route: [''] // Add route when defined
  },
  {
    label: 'main.list.flashDevice.title',
    disabled: false,
    route: [ROUTES.MAIN, MAIN_ROUTES.FLASH_DEVICE]
  }
];

export const LANGUAGES: Language[] = [
  {
    locale: 'de',
    label: 'Deutsch'
  },
  {
    locale: 'en',
    label: 'English'
  }
];
