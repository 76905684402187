/* eslint-disable @typescript-eslint/naming-convention */
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ButtonEventEnum, ButtonTypeEnum, PositionTypeEnum } from '@core/enums';

import { Directive } from '@angular/core';
import { ROUTES } from '@app/app-routing.config';
import { Router } from '@angular/router';
import { BroadcastRegister, DeviceResponse } from '@app/shared/interfaces/interfaces';
import { BroadcastCategory } from '@app/shared/enums/enums';

export class McpDeviceError extends Error {
  // error ranges:
  // 0 - 255: directly from mcp device (BASE_MCP_DEVICE + ERRORCODE)
  static readonly BASE_MCP_DEVICE = 0;
  // 1000 - 1999: cloud upload (BASE_CLOUD_UPLOAD + ERRORCODE)
  static readonly BASE_CLOUD_UPLOAD = 1000;
  // 2000+: internal (BASE_INTERNAL + ERRORCODE)
  static readonly BASE_INTERNAL = 2000;
  static readonly ERROR_UNKNOWN = McpDeviceError.BASE_INTERNAL + 1;
  static readonly ERROR_DOOR_STATE_UNLEARNED = McpDeviceError.BASE_INTERNAL + 2;
  static readonly ERROR_MCP_DEVICE_CONNECT_FAILED =
    McpDeviceError.BASE_INTERNAL + 3;

  static readonly ERROR_MCP_DEVICE_NOT_CONNECTED =
    McpDeviceError.BASE_INTERNAL + 4;

  static readonly ERROR_MCP_DEVICE_COMM_TIMEOUT =
    McpDeviceError.BASE_INTERNAL + 5;

  static readonly ERROR_REQUIRED_INFO_MISSING =
    McpDeviceError.BASE_INTERNAL + 6;

  constructor(message: string, public readonly code: number) {
    super(message);
    this.name = 'McpDeviceCommError';
  }

  override toString() {
    return `${this.name}: ${this.message} (Code:${this.code})`;
  }
}

@Directive()
export abstract class AbstractMcpDeviceService {
  public readonly broadcastObservablesKVP: Record<number, BehaviorSubject<unknown>> = {};

  public readonly connected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public readonly fineTuningReady$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  public readonly endPositionOpenedReady$: Subject<boolean> =
    new Subject<boolean>();

  public readonly endPositionClosedReady$: Subject<boolean> =
    new Subject<boolean>();

  public readonly intermediatePositionOpenedReady$: Subject<boolean> =
    new Subject<boolean>();

  public readonly intermediatePositionClosedReady$: Subject<boolean> =
    new Subject<boolean>();

  public readonly isDoorDirectionReversed$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public readonly errorOccured$: Subject<number> = new Subject<number>();

  public readonly initialBroadcastsRegistered$: Subject<boolean> = new Subject<boolean>();

  abstract getBroadcastById(id: number): BroadcastRegister | undefined;
  abstract registerBroadcastsByCategory(category: BroadcastCategory): void;
  abstract areAllinitialBroadcastsRegistered: boolean;
  abstract updateOnId: BehaviorSubject<DeviceResponse | null>;
  abstract updateOnDisconnect: BehaviorSubject<boolean | null>;
  abstract clbk_connect(): void;
  abstract clbk_disconnect(): void;
  abstract clbk_epo_save(): Observable<void>;
  abstract clbk_epo_move(
    button: ButtonTypeEnum,
    buttonevent: ButtonEventEnum,
    reversedirection: boolean
  ): void;
  abstract clbk_epc_move(
    button: ButtonTypeEnum,
    buttonevent: ButtonEventEnum,
    reversedirection: boolean
  ): void;
  abstract clbk_ips_move(
    button: ButtonTypeEnum,
    buttonevent: ButtonEventEnum
  ): void;

  abstract clbk_ipo_save(): Observable<void>;
  abstract clbk_ipc_save(): Observable<void>;

  abstract clbk_ip_deleteintpos(intposopen: boolean): Observable<void>;
  abstract clbk_getswitchposition(): Observable<boolean>;
  abstract clbk_getfromid(id: number): Observable<any[]>;
  abstract registerIds(ids: number[]): Promise<any>;
  abstract unregisterIds(ids: number[]): Promise<any>;
  abstract clbk_writetoid(
    id: number,
    value: number | number[] | undefined
  ): Observable<any[]>;
  abstract clbk_getlearningstateendpos(): Observable<boolean>;
  abstract clbk_getpreconditionsstatefinetuning(): Observable<boolean>;
  abstract clbk_savedoordirectionswitchposition(
    reverseDoorDirection: boolean
  ): Observable<void>;
  abstract clbk_fto_save(ftp_epo: number): Observable<void>;
  abstract clbk_ftc_save(ftp_epc: number): Observable<void>;
  abstract clbk_ispositionsaved(
    positiontype: PositionTypeEnum
  ): Observable<boolean>;
  abstract clbk_approach_epo(): void;
  abstract clbk_approach_epc(): void;
  abstract clbk_approach_ipo(): void;
  abstract clbk_approach_ipc(): void;
  abstract f_ep_setup_abort(): void;
  abstract f_epo_change_abort(): void;
  abstract f_epc_change_abort(): void;
  abstract clbk_initiateendpossetup(): Observable<void>;
  abstract clbk_initiateendposopenedchange(): Observable<void>;
  abstract clbk_initiateendposclosedchange(): Observable<void>;
  abstract clbk_IP_initiatelrn(): Observable<void>;
  abstract clbk_IP_abortlrn(): void;
  abstract clbk_epo_save_and_calibrate(): Observable<void>;
  abstract clbk_epc_save_and_calibrate(): Observable<void>;
  abstract clbk_epc_save(): Observable<void>;
  abstract mcp_stop_movement(): void;
  abstract clbk_fto_epo_approach(): void;
  abstract clbk_fto_epc_approach(): void;
  abstract clbk_ftc_epo_approach(): void;
  abstract clbk_ftc_epc_approach(): void;
  abstract clbk_get_ftp_epo(): Observable<number>;
  abstract clbk_get_ftp_epc(): Observable<number>;

  constructor(protected router: Router) {}

  // FUNCTIONS // Added naming convention to extra functions finished and connected
  clbk_connected(): Observable<boolean> {
    return this.connected$;
  }

  clbk_connectionlost() {
    this.router.navigate([ROUTES.CONNECTION_LOST]);
  }

  setConnected(isConnected: boolean): void {
    this.connected$.next(isConnected);
  }

  getFineTuningModeReady(): Observable<boolean> {
    return this.fineTuningReady$.asObservable();
  }

  protected setFineTuningModeReady(value: boolean): void {
    this.fineTuningReady$.next(value);
  }

  clbk_fto_is_door_moving(doormovement: boolean): void {
    const isReady = !doormovement;

    this.setFineTuningModeReady(isReady);
  }

  clbk_ftc_is_door_moving(doormovement: boolean): void {
    const isReady = !doormovement;

    this.setFineTuningModeReady(isReady);
  }

  clbk_epoready(): void {
    this.endPositionOpenedReady$.next(true);
  }

  getEndPositionOpenedReady(): Observable<boolean> {
    return this.endPositionOpenedReady$.asObservable();
  }

  protected setEndPositionOpenedReady(value: boolean): void {
    this.endPositionOpenedReady$.next(value);
  }

  clbk_epcready(): void {
    this.endPositionClosedReady$.next(true);
  }

  getEndPositionClosedReady(): Observable<boolean> {
    return this.endPositionClosedReady$.asObservable();
  }

  protected setEndPositionClosedReady(value: boolean): void {
    this.endPositionClosedReady$.next(value);
  }

  clbk_ipoready(): void {
    this.intermediatePositionOpenedReady$.next(true);
  }

  getIntermediatePositionOpenedReady(): Observable<boolean> {
    return this.intermediatePositionOpenedReady$.asObservable();
  }

  protected setIntermediatePositionOpenedReady(value: boolean): void {
    this.intermediatePositionOpenedReady$.next(value);
  }

  clbk_ipcready(): void {
    this.intermediatePositionClosedReady$.next(true);
  }

  getIntermediatePositionClosedReady(): Observable<boolean> {
    return this.intermediatePositionClosedReady$.asObservable();
  }

  protected setIntermediatePositionClosedReady(value: boolean): void {
    this.intermediatePositionClosedReady$.next(value);
  }

  protected setIsDoorDirectionReversed(value: boolean): void {
    this.isDoorDirectionReversed$.next(value);
  }

  protected getIsDoorDirectionReversed$(): Observable<boolean> {
    return this.isDoorDirectionReversed$.asObservable();
  }

  clbk_show_error(errorcode: number): void {
    this._setErrorOccured(errorcode);
  }

  getErrorOccured$(): Observable<number> {
    return this.errorOccured$.asObservable();
  }

  private _setErrorOccured(errorCode: number): void {
    this.errorOccured$.next(errorCode);
  }
}
