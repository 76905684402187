/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  from,
  Observable,
  throwError
} from 'rxjs';

import {
  AbstractMcpDeviceService,
  AbstractMcpDeviceCommService,
  McpDeviceError
} from '@core/services';

import { ButtonEventEnum, ButtonTypeEnum, PositionTypeEnum } from '@core/enums';
import { DeviceResponse } from '@app/shared/interfaces/interfaces';
import { BroadcastService, CommId } from './broadcast.service';
import { BroadcastCategory } from '@app/shared/enums/enums';
import { ROUTES } from '@app/app-routing.config';

// MCP enums
export enum DoorLearnStateEnum {
  _unknown = -1,
  learned,
  learning,
  unlearned,
  imeLearning,
  runInNeeded
}

export enum EndPositionTypeEnum {
  _unknown = -1,
  awg,
  mechanicalEndSwitches,
  autolearnEndPositionType
}

export enum RotatingFieldEnum {
  _unknown = -1,
  default,
  reversedRotating
}

export enum LearningCommandEnum {
  _unknown = -1,
  requestLearning,
  saveOpen,
  saveClose,
  skipOpen,
  skipClose,
  abortLearning,
  driveOpen,
  driveClose,
  driveStop
}

export enum LearningImePositionEnum {
  _unknown = -1,
  requestLearning,
  abortLearning,
  saveImeOpen,
  saveImeClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint,
  stopDriving,
  openDriving,
  closeDriving
}

export enum DeleteImePositionEnum {
  _unknown = -1,
  allImePositions,
  allSpPositions,
  allPositions,
  imePositionOpen,
  imePositionClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint
}

export enum ImpulsTargetEnum {
  _unknown = -1,
  endPositionOpen,
  endPositionClose,
  imePositionOpen,
  imePositionClose,
  positionStop,
  posSpLsPoint,
  posSpDeactivationClose,
  posSpDeactivationOpen,
  posSpSafetyEdgeTestPoint
}

export enum MotorStateEnum {
  _unknown = -1,
  notInstalled,
  stop,
  close,
  open
}

export enum CurrentDoorPositionEnum {
  _unknown = -1,
  unknownInvalid,
  untrainedEndPosition,
  overEndPosition,
  endPositionOpen,
  intermediateOpen,
  betweenEndPositions,
  betweenEpCloseAndImeOpen,
  betweenEpOpenAndImeClose,
  betweenEpCloseAndImeClose,
  betweenEpOpenAndImeOpen,
  betweenImeOpenAndImeClose,
  intermediateClose,
  endPositionClose,
  overEndPositionClose
}

// internal
enum MoveTypeEnum {
  endpos = 'endpos',
  intermediatepos = 'intermediatepos'
}

enum MoveStateEnum {
  stopped = 'stopped',
  running = 'running',
  stopping = 'stopping'
}

@Injectable({
  providedIn: 'root'
})
export class McpDeviceService extends AbstractMcpDeviceService {
  registeredIds: Record<number, Observable<unknown>> = {};
  private _notUpdatedIds: number[] = [];

  // static readonly INFO_READOUT_POST_URL = 'https://api.test.yourgateway.io/api/box-reports/add';
  static readonly INFO_READOUT_POST_URL = 'https://pullreportcustomertest.azurewebsites.net/api/box-reports/add';

  static readonly INFO_READOUT_POST_API_KEY = 'Xnd0JiFcbURjdHhTIDQe3MgI';
  static readonly INFO_READOUT_POST_CLIENT_ID = 'maveoserviceapp';

  // Misc defines
  static readonly MOVE_COMMAND_INTERVAL_MS = 500;
  static readonly END_POSITION_FINE_SETTINGS_MIN = 0;
  static readonly END_POSITION_FINE_SETTINGS_MAX = 2000;

  static readonly DEFAULT_READ_MAX_TRY_COUNT = 3;
  static readonly DEFAULT_WRITE_MAX_TRY_COUNT = 1;
  static readonly IMPORTANT_WRITE_MAX_TRY_COUNT = 3;

  static readonly DEBUG = true;

  static readonly TIMEOUT_WRITE_TO_ID = 1000;

  private moveTimer: ReturnType<typeof setTimeout>;
  private moveTimerFunc: () => void;
  private moveType: MoveTypeEnum;
  private moveState: MoveStateEnum = MoveStateEnum.stopped;

  private motorStateChangedFunc = (state: MotorStateEnum[]) => {};
  private currentMotorState: MotorStateEnum = MotorStateEnum._unknown;

  updateOnId: BehaviorSubject<DeviceResponse | null> =
    new BehaviorSubject<DeviceResponse | null>(null);

  updateOnDisconnect: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  areAllinitialBroadcastsRegistered = false;

  constructor(
    router: Router,
    private readonly comm: AbstractMcpDeviceCommService,
    private readonly _broadcastService: BroadcastService
  ) {
    super(router);

    this.comm.setDebugLogCallback(this.onDebugLog.bind(this));
    this.comm.setReceiveResponseCallback(this.onReceiveResponse.bind(this));
    this._broadcastService.registerIdsEvent.subscribe(ids => {
      this.comm.registerId(ids);
    });
    this._broadcastService.unregisterIdsEvent.subscribe(ids => {
      this.comm.unregisterId(ids);
    });
  }

  //
  // Connection methods
  //

  clbk_connect(): void {
    console.log('[McpDeviceService] >>> clbk_connect');

    this.comm
      .connect(
        this.onReceiveUpdate.bind(this),
        this.onConnectionLost.bind(this)
      )
      .then(() => {
        console.log('[McpDeviceService] Connected');
        this.setConnected(true);
        this._broadcastService.init();
      })
      .catch((error: any) => {
        console.error('[McpDeviceService] Connect failed: ' + String(error));
        this.clbk_connectionlost();
      });
  }

  clbk_disconnect(): void {
    console.log('>>> clbk_disconnect');

    this.comm
      .disconnect()
      .then(() => {
        console.log('Disconnected');
      })
      .catch((error: any) => {
        console.error('[McpDeviceService] Disconnect failed (ignoring): ' + String(error));
      })
      .finally(() => {
        this._reset();
        this.setConnected(false);
        this.router.navigate([ROUTES.LANDING_PAGE]);
      });
  }

  async registerIds(ids: number[]) {
    try {
      console.log('[McpDeviceService] Successfully registered ID(s)');
      return await this.comm.registerId(ids);
    } catch (error) {
      console.error('[McpDeviceService] Registering ID(s) failed: ' + String(error));
      throwError(() => error);
    }
  }

  async unregisterIds(ids: number[]) {
    try {
      return await this.comm
      .unregisterId(ids);
    } catch (error) {
      console.error('[McpDeviceService] Unregistering ID(s) failed: ' + String(error));
      throwError(() => error);
    }
  }

  //
  // Setup positions methods
  //

  clbk_getfromid(id: number): Observable<any[]> {
    console.log('[McpDeviceService] >>> clbk_getfromid');

    return from(this.comm.readId(id));
  }

  clbk_writetoid(id: number, value: number | number[]): Observable<any[]> {
    console.log('[McpDeviceService] >>> clbk_writetoid');

    return from(this.comm.writeId(id, value, McpDeviceService.TIMEOUT_WRITE_TO_ID));
  }

  clbk_getlearningstateendpos(): Observable<boolean> {
    console.log('[McpDeviceService] >>> clbk_getlearningstateendpos');

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();

        const result = dls === DoorLearnStateEnum.learned;
        console.log('[McpDeviceService] clbk_getlearningstateendpos result=' + String(result));

        return result;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_getpreconditionsstatefinetuning(): Observable<boolean> {
    console.log('[McpDeviceService] >>> clbk_getpreconditionsstatefinetuning');

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        let result = dls === DoorLearnStateEnum.learned;

        if (result) {
          const ept = await this.readEndPositionType();
          result = ept === EndPositionTypeEnum.awg;
        }

        console.log(
          '[McpDeviceService] clbk_getpreconditionsstatefinetuning result=' + String(result)
        );

        return result;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  //
  // Setup end positions methods
  //

  clbk_initiateendpossetup(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_initiateendpossetup');

    if (McpDeviceService.DEBUG) {
      // this.comm.registerId([
      //   CommId.ID_CURRENT_VALUE_AWG,
      //   CommId.ID_DOOR_LEARN_STATE
      // ]);
    }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        // no longer needed
        // I leave it here for the moment in case there is a change of mind
        // in the near future
        // await this.writeEndPositionType(
        //   EndPositionTypeEnum.awg,
        //   McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        // );

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  private f_ep_setup_abort_generic(): Observable<void> {
    this.stopMove();
    return from(
      this.writeLearningCommand(
        LearningCommandEnum.abortLearning,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      ).catch(() => undefined)
    );
  }

  f_ep_setup_abort(): void {
    console.log('[McpDeviceService] >>> f_ep_setup_abort');
    this.f_ep_setup_abort_generic();
  }

  clbk_getswitchposition(): Observable<boolean> {
    console.log('[McpDeviceService] >>> clbk_getswitchposition');

    return from(
      (async () => {
        const rf = await this.readRotatingField();

        return rf === RotatingFieldEnum.reversedRotating;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_savedoordirectionswitchposition(position: boolean): Observable<void> {
    console.log(
      '[McpDeviceService] >>> clbk_savedoordirectionswitchposition position=' + String(position)
    );
    return from(
      this.writeRotatingField(
        position
          ? RotatingFieldEnum.reversedRotating
          : RotatingFieldEnum.default,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  private stopMove(): void {
    console.log('[McpDeviceService] >>> stopMove moveState=' + String(this.moveState));
    if (this.moveState === MoveStateEnum.running) {
      this.moveState = MoveStateEnum.stopping;
      clearTimeout(this.moveTimer);

      // send drive-stop
      switch (this.moveType) {
        case MoveTypeEnum.endpos:
          this.writeLearningCommand(
            LearningCommandEnum.driveStop,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          )
            .catch(() => undefined)
            .finally(() => {
              this.moveState = MoveStateEnum.stopped;
            });
          break;

        case MoveTypeEnum.intermediatepos:
          this.writeLearningImePosition(
            LearningImePositionEnum.stopDriving,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          )
            .catch(() => undefined)
            .finally(() => {
              this.moveState = MoveStateEnum.stopped;
            });
          break;
      }
    }
  }

  private clbk_ep_move_generic(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    if (buttonEvent === ButtonEventEnum.buttonpressed) {
      if (this.moveState !== MoveStateEnum.stopped) {
        console.warn(
          '[McpDeviceService] another move operation is in progress (moveState=' +
            this.moveState +
            '), ignoring'
        );
        return;
      }

      // start/change cyclical sending of drive-open/close learning commands
      this.moveType = MoveTypeEnum.endpos;
      this.moveState = MoveStateEnum.running;

      this.moveTimerFunc = () => {
        console.log('[McpDeviceService] >>> clbk_ep_move_generic cyclic buttonType=' + buttonType);
        this.writeLearningCommand(
          buttonType === ButtonTypeEnum.buttonup
            ? LearningCommandEnum.driveOpen
            : LearningCommandEnum.driveClose
        )
          .then(() => {
            if (this.moveState === MoveStateEnum.running) {
              this.moveTimer = setTimeout(() => {
                this.moveTimerFunc();
              }, McpDeviceService.MOVE_COMMAND_INTERVAL_MS);
            }
          })
          .catch((error) => {
            this.stopMove();
            this.showErrorCode(error);
          });
      };

      this.moveTimerFunc();
    } else {
      // stop cyclical sending of drive-open/close learning commands and send stop
      this.stopMove();
    }
  }

  clbk_epo_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    console.log(
      '[McpDeviceService] >>> clbk_epo_move buttonType=' +
        String(buttonType) +
        ', buttonEvent=' +
        String(buttonEvent) +
        ', reverseDirection=' +
        String(reverseDirection)
    );

    this.clbk_ep_move_generic(buttonType, buttonEvent, reverseDirection);
  }

  clbk_epo_save(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_epo_save');

    this.stopMove();
    return from(
      this.writeLearningCommand(
        LearningCommandEnum.saveOpen,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_epc_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    console.log(
      '[McpDeviceService] >>> clbk_epc_move buttonType=' +
        String(buttonType) +
        ', buttonEvent=' +
        String(buttonEvent) +
        ', reverseDirection=' +
        String(reverseDirection)
    );

    this.clbk_ep_move_generic(buttonType, buttonEvent, reverseDirection);
  }

  clbk_epc_save_and_calibrate(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_epc_save_and_calibrate');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionOpen,
          this.clbk_epoready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionOpenedReady());

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        console.log('[McpDeviceService] clbk_epc_save_and_calibrate succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_epc_save(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_epc_save');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
        console.log('[McpDeviceService] clbk_epc_save succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_initiateendposopenedchange(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_initiateendposopenedchange');

    // if (McpDeviceService.DEBUG) {
    //   this.comm.registerId([
    //     CommId.ID_CURRENT_VALUE_AWG,
    //     CommId.ID_DOOR_LEARN_STATE
    //   ]);
    // }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            '[McpDeviceService] Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  f_epo_change_abort() {
    console.log('[McpDeviceService] >>> f_epo_change_abort');
    this.f_ep_setup_abort_generic();
  }

  clbk_epo_save_and_calibrate(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_epo_save_and_calibrate');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveOpen,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
        await this.writeLearningCommand(
          LearningCommandEnum.skipClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionOpen,
          this.clbk_epoready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionOpenedReady());

        // currently MCP will only accept a runIn-test from open to close it seems *sigh*..
        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        console.log('[McpDeviceService] clbk_epo_save_and_calibrate succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_initiateendposclosedchange(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_initiateendposclosedchange');

    // if (McpDeviceService.DEBUG) {
    //   this.comm.registerId([
    //     CommId.ID_CURRENT_VALUE_AWG,
    //     CommId.ID_DOOR_LEARN_STATE
    //   ]);
    // }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            '[McpDeviceService] Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
        await this.writeLearningCommand(LearningCommandEnum.skipOpen);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  f_epc_change_abort(): void {
    console.log('[McpDeviceService] >>> f_epc_change_abort');
    this.f_ep_setup_abort_generic();
  }

  //
  // Setup intermediate positions methods
  //

  clbk_ip_deleteintpos(intposopen: boolean): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_ip_deleteintpos intposopen=' + String(intposopen));
    return from(
      this.writeDeleteImePosition(
        intposopen
          ? DeleteImePositionEnum.imePositionOpen
          : DeleteImePositionEnum.imePositionClose,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_IP_initiatelrn(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_IP_initiatelrn');

    // if (McpDeviceService.DEBUG) {
    //   this.comm.registerId([
    //     CommId.ID_CURRENT_VALUE_AWG,
    //     CommId.ID_DOOR_LEARN_STATE
    //   ]);
    // }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            '[McpDeviceService] Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningImePosition(
          LearningImePositionEnum.requestLearning
        );
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_IP_abortlrn(): void {
    console.log('[McpDeviceService] >>> clbk_IP_abortlrn');
    this.stopMove();
    this.writeLearningImePosition(
      LearningImePositionEnum.abortLearning,
      McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
    ).catch(() => undefined);
  }

  clbk_ips_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum
  ): void {
    console.log(
      '[McpDeviceService] >>> clbk_ips_move buttonType=' +
        buttonType +
        ', buttonEvent=' +
        buttonEvent
    );

    if (buttonEvent === ButtonEventEnum.buttonpressed) {
      if (this.moveState !== MoveStateEnum.stopped) {
        console.warn(
          '[McpDeviceService] another move operation is in progress (moveState=' +
            this.moveState +
            '), ignoring'
        );
        return;
      }

      // start/change cyclical sending of drive-open/close learning commands
      this.moveType = MoveTypeEnum.intermediatepos;
      this.moveState = MoveStateEnum.running;

      this.moveTimerFunc = () => {
        console.log('[McpDeviceService] >>> clbk_ips_move cyclic buttonType=' + buttonType);
        this.writeLearningImePosition(
          buttonType === ButtonTypeEnum.buttonup
            ? LearningImePositionEnum.openDriving
            : LearningImePositionEnum.closeDriving
        )
          .then(() => {
            if (this.moveState === MoveStateEnum.running) {
              this.moveTimer = setTimeout(() => {
                this.moveTimerFunc();
              }, McpDeviceService.MOVE_COMMAND_INTERVAL_MS);
            }
          })
          .catch((error) => {
            this.stopMove();
            this.showErrorCode(error);
          });
      };

      this.moveTimerFunc();
    } else {
      // stop cyclical sending of drive-open/close learning commands and send stop
      this.stopMove();
    }
  }

  clbk_ipo_save(): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_ipo_save');

    this.stopMove();
    return from(
      this.writeLearningImePosition(LearningImePositionEnum.saveImeOpen)
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_ipc_save(): Observable<void> {
    console.log('>>> clbk_ipc_save');

    this.stopMove();
    return from(
      this.writeLearningImePosition(LearningImePositionEnum.saveImeClose)
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  //
  // Testing positions methods
  //

  clbk_ispositionsaved(positiontype: PositionTypeEnum): Observable<boolean> {
    console.log('[McpDeviceService] >>> clbk_ispositionsaved positiontype=' + positiontype);

    if (
      positiontype === PositionTypeEnum.epo ||
      positiontype === PositionTypeEnum.epc
    ) {
      return from(
        this.readDoorLearnState().then((dls: DoorLearnStateEnum) => {
          return dls === DoorLearnStateEnum.learned;
        })
      ).pipe(catchError(this.throwErrorCode.bind(this)));
    } else {
      // ipo/ipc
      return from(
        this.readIntermediatePositions().then((ip: number[]) => {
          return ip[positiontype === PositionTypeEnum.ipo ? 0 : 1] !== 0;
        })
      ).pipe(catchError(this.throwErrorCode.bind(this)));
    }
  }

  private clearMotorStateChangedFunc(): void {
    this.motorStateChangedFunc = (state: MotorStateEnum[]) => {};
    this.currentMotorState = MotorStateEnum._unknown;
  }

  private async clbk_approach_generic(
    impulsTarget: ImpulsTargetEnum,
    callbackStop: any,
    callbackMoving: any
  ): Promise<void> {
    // if (McpDeviceService.DEBUG) {
    //   this.comm.registerId([
    //     CommId.ID_CURRENT_VALUE_AWG,
    //     CommId.ID_DOOR_LEARN_STATE
    //   ]);
    // }

    this.clearMotorStateChangedFunc();

    let checkCurrentDoorPosition: CurrentDoorPositionEnum | null = null;

    switch (impulsTarget) {
      case ImpulsTargetEnum.endPositionOpen:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.endPositionOpen;
        break;

      case ImpulsTargetEnum.endPositionClose:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.endPositionClose;
        break;

      case ImpulsTargetEnum.imePositionOpen:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.intermediateOpen;
        break;

      case ImpulsTargetEnum.imePositionClose:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.intermediateClose;
        break;
    }

    this.motorStateChangedFunc = (state: MotorStateEnum[]) => {
      const oldState: MotorStateEnum = this.currentMotorState;

      // the initial motorstate always needs to begin with stop, we ignore any noise before that
      // else we can't properly detect the desired state changes when door was already moving
      if (
        oldState !== MotorStateEnum._unknown ||
        state[0] === MotorStateEnum.stop
      ) {
        this.currentMotorState = state[0];
      }

      if (oldState === MotorStateEnum._unknown || oldState === state[0]) {
        // we are only interested in well-defined state changes, ignore
        return;
      }

      if (
        (oldState === MotorStateEnum.open ||
          oldState === MotorStateEnum.close) &&
        state[0] === MotorStateEnum.stop
      ) {
        // open/close -> stop
        console.log(
          '[McpDeviceService] clbk_approach_generic motor state changed from open/close to stop'
        );
        this.clearMotorStateChangedFunc();
        this.comm
          .unregisterId(CommId.ID_MOTOR_STATE)
          .catch(() => undefined); // we just try to unregister and don't wait
        callbackStop();
      } else if (
        oldState === MotorStateEnum.stop &&
        (state[0] === MotorStateEnum.open || state[0] === MotorStateEnum.close)
      ) {
        // stop -> open/close
        console.log(
          '[McpDeviceService] clbk_approach_generic motor state changed from stop to open/close'
        );
        if (callbackMoving !== undefined) {
          callbackMoving();
        }
      }
    };

    return await this.comm
      .unregisterId(CommId.ID_MOTOR_STATE) // make sure motor state is unregistered so we definitiely get the initial value when re-registering
      .then(async () => {
        // we really want to always start with a well-defined stop state because the door could currently be moving already in either
        // the correct direction (no stop of motor happens in between then) or the opposite direction (a stop happens in between then)
        // else its impossible to properly detect the desired state changes and not misinterpret them
        await this.writeImpulsTarget(ImpulsTargetEnum.positionStop);

        await this.comm.registerId(CommId.ID_MOTOR_STATE);

        // should we test if we are already at the desired position without sending the impuls target?
        let cdp = CurrentDoorPositionEnum._unknown;
        if (checkCurrentDoorPosition != null) {
          cdp = await this.readCurrentDoorPosition();
        }

        // already at desired position?
        if (
          checkCurrentDoorPosition != null &&
          cdp === checkCurrentDoorPosition
        ) {
          console.log(
            '[McpDeviceService] clbk_approach_generic current door position is already at desired position'
          );
          this.clearMotorStateChangedFunc();
          this.comm.unregisterId(CommId.ID_MOTOR_STATE); // we just try to unregister and don't wait
          callbackStop();
        } else {
          await this.writeImpulsTarget(impulsTarget);
        }
      })
      .catch((error: any) => {
        this.clearMotorStateChangedFunc();
        this.comm.unregisterId(CommId.ID_MOTOR_STATE); // we just try to unregister and don't wait
        console.error('[McpDeviceService] clbk_approach_generic failed: ' + String(error));

        // we can be called either with noone waiting for us (but observing showerror), or waiting for us and reacting to throw
        this.showErrorCode(error);
        this.throwErrorCode(error);
      });
  }

  clbk_approach_epo(): void {
    console.log('[McpDeviceService] >>> clbk_approach_epo');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_epoready.bind(this),
      undefined
    );
  }

  clbk_approach_epc(): void {
    console.log('[McpDeviceService] >>> clbk_approach_epc');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_epcready.bind(this),
      undefined
    );
  }

  clbk_approach_ipo(): void {
    console.log('[McpDeviceService] >>> clbk_approach_ipo');
    this.clbk_approach_generic(
      ImpulsTargetEnum.imePositionOpen,
      this.clbk_ipoready.bind(this),
      undefined
    );
  }

  clbk_approach_ipc(): void {
    console.log('[McpDeviceService] >>> clbk_approach_ipc');
    this.clbk_approach_generic(
      ImpulsTargetEnum.imePositionClose,
      this.clbk_ipcready.bind(this),
      undefined
    );
  }

  mcp_stop_movement(): void {
    console.log('[McpDeviceService] >>> mcp_stop_movement');
    this.clearMotorStateChangedFunc();
    this.writeImpulsTarget(
      ImpulsTargetEnum.positionStop,
      McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
    );
    this.comm.unregisterId(CommId.ID_MOTOR_STATE); // we just try to unregister and don't wait

    // this is just to make sure the UI doesn't stay in disabled state (if any)
    this.clbk_fto_is_door_moving(false);
  }

  //
  // Finetuning positions methods
  //

  private async get_ftp_ep_generic(open: boolean): Promise<number> {
    return await this.readDiagnosisEndPositionFineSettings().then(
      (epfs: number[]) => {
        return epfs[open ? 0 : 1];
      }
    );
  }

  clbk_get_ftp_epo(): Observable<number> {
    console.log('[McpDeviceService] >>> clbk_get_ftp_epo');
    return from(this.get_ftp_ep_generic(true)).pipe(
      catchError(this.throwErrorCode.bind(this))
    );
  }

  clbk_get_ftp_epc(): Observable<number> {
    console.log('[McpDeviceService] >>> clbk_get_ftp_epc');
    return from(this.get_ftp_ep_generic(false)).pipe(
      catchError(this.throwErrorCode.bind(this))
    );
  }

  private save_ftp_generic(open: boolean, value: number): Observable<void> {
    return from(
      this.get_ftp_ep_generic(!open).then(async (value_other) => {
        return await this.writeDiagnosisEndPositionFineSettings(
          open ? [value, value_other] : [value_other, value],
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
      })
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_fto_save(ftp_epo: number): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_fto_save ftp_epo=', ftp_epo);
    return this.save_ftp_generic(true, ftp_epo);
  }

  clbk_ftc_save(ftp_epc: number): Observable<void> {
    console.log('[McpDeviceService] >>> clbk_ftc_save ftp_epc=', ftp_epc);
    return this.save_ftp_generic(false, ftp_epc);
  }

  clbk_fto_epo_approach(): void {
    console.log('[McpDeviceService] >>> clbk_fto_epo_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_fto_approach_door_stopped.bind(this),
      this.clbk_fto_approach_door_moving.bind(this)
    );
  }

  clbk_fto_epc_approach(): void {
    console.log('[McpDeviceService] >>> clbk_fto_epc_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_fto_approach_door_stopped.bind(this),
      this.clbk_fto_approach_door_moving.bind(this)
    );
  }

  private clbk_fto_approach_door_moving(): void {
    this.clbk_fto_is_door_moving(true);
  }

  private clbk_fto_approach_door_stopped(): void {
    this.clbk_fto_is_door_moving(false);
  }

  clbk_ftc_epo_approach(): void {
    console.log('[McpDeviceService] >>> clbk_ftc_epo_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_ftc_approach_door_stopped.bind(this),
      this.clbk_ftc_approach_door_moving.bind(this)
    );
  }

  clbk_ftc_epc_approach(): void {
    console.log('[McpDeviceService] >>> clbk_ftc_epc_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_ftc_approach_door_stopped.bind(this),
      this.clbk_ftc_approach_door_moving.bind(this)
    );
  }

  private clbk_ftc_approach_door_moving(): void {
    this.clbk_ftc_is_door_moving(true);
  }

  private clbk_ftc_approach_door_stopped(): void {
    this.clbk_ftc_is_door_moving(false);
  }

  //
  // Internal helper methods
  //

  private throwErrorCode(error: any): never {
    if (!(error instanceof McpDeviceError)) {
      error = new McpDeviceError(error.message, McpDeviceError.ERROR_UNKNOWN);
    }

    throw error.code;
  }

  private showErrorCode(error: any): void {
    if (!(error instanceof McpDeviceError)) {
      error = new McpDeviceError(error.message, McpDeviceError.ERROR_UNKNOWN);
    }

    this.clbk_show_error(error.code);
  }

  protected onConnectionLost() {
    console.warn('Connection lost');
    this._reset();
    this.setConnected(false);
    this.clbk_connectionlost();
  }

  private _reset() {
    this.areAllinitialBroadcastsRegistered = false;
    this.updateOnDisconnect.next(true);
    this._broadcastService.reset();
  }

  protected onReceiveUpdate(json: DeviceResponse) {
    if (json) {
      this.updateOnId.next(json);
    }
    if (json.nrc === undefined || json.nrc === 'NULL') {
      const values = json.d;
      if (json.id !== 32) { // @todo: remove condition
        console.log(
          '[McpDeviceService] Received update for ID:' + String(json.id) + ' Value(s):',
          values
        );
      }

      this._broadcastService.setAsRegistered(json.id, true);
      this.areAllinitialBroadcastsRegistered || this._checkAllInitialBroadcastsRegistered();

      const broadcast = this.getBroadcastById(json.id);
      let dataToSend: unknown = values;
      if (broadcast) {
        if (broadcast?.rule) {
          dataToSend = broadcast.rule(values);
        }
        const subj = broadcast.observable as BehaviorSubject<unknown>;
        subj.next(dataToSend);
      } else {
        // special cases
        switch (json.id) {
          case CommId.ID_MOTOR_STATE: {
            const msv = [
              values[0] as MotorStateEnum,
              values[1] as MotorStateEnum
            ];
            this.motorStateChangedFunc(msv);
            break;
          }
        }
      }
    } else {
      console.error(
        '[McpDeviceService] Received update error for ID:' +
          String(json.id) +
          ' Error:' +
          String(json.nrc)
      );
    }
  }

  protected onDebugLog(message: any) {
    console.log('McpDebug:', message);
  }

  protected onReceiveResponse(json: any) {
    // filter out update responses (we don't want duplicate debug prints for them)
    if (json.res !== 'u') {
      console.log('[McpDeviceService] Received response:', json);
      if (json.nrc !== undefined && json.nrc !== 'NULL' ) {
        this._notUpdatedIds.push(json.id);
        console.log('[McpDeviceService] onReceiveResponse - notUpdatedIds: ', this._notUpdatedIds);
      }
    }
  }

  private _checkAllInitialBroadcastsRegistered() {
    this.areAllinitialBroadcastsRegistered = this._broadcastService.areAllStartIdsRegistered();
    !this.areAllinitialBroadcastsRegistered || this.initialBroadcastsRegistered$.next(true);

  }

  private async readDoorLearnState() {
    let doorLearnState = DoorLearnStateEnum._unknown;
    const observable = this._broadcastService.getObservableById(CommId.ID_DOOR_LEARN_STATE);
    if (observable) {
      const value = await firstValueFrom(observable) as unknown as string;
      const key = value as keyof typeof DoorLearnStateEnum;
      doorLearnState = DoorLearnStateEnum[key]
    }
    return doorLearnState;
  }

  private async readDoorLearnStateFromDevice(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<DoorLearnStateEnum> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          CommId.ID_DOOR_LEARN_STATE
        );
        const doorLearnState = values[0] as DoorLearnStateEnum;
        console.log(
          '[McpDeviceService] >>> readDoorLearnState doorLearnState',
          DoorLearnStateEnum[doorLearnState]
        );
        return doorLearnState;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] >>> readDoorLearnState failed to read ID ' +
              String(CommId.ID_DOOR_LEARN_STATE) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readEndPositionType(): Promise<EndPositionTypeEnum> {
    try {
      const observable = this.getBroadcastById(CommId.ID_END_POSITION_TYPE)?.observable;
      if (observable) {
        const values = await firstValueFrom(observable) as unknown as number[];
        const endPositionType = values[0] as EndPositionTypeEnum;
        console.log(
          '[McpDeviceService] >>> readEndPositionType endPositionType',
          EndPositionTypeEnum[endPositionType]
        );
        return endPositionType;
      } else {
        throw new Error('[McpDeviceService] >>> readEndPositionType failed to read ID: ' + CommId.ID_END_POSITION_TYPE + '. Observable missing.');
      }
    } catch (error: any) {
      console.error(
        '[McpDeviceService] >>> readEndPositionType failed to read ID ' +
          String(CommId.ID_END_POSITION_TYPE) +
          ': ' +
          String(error)
      );
      throw error;
    }
  }

  private async readRotatingField(): Promise<RotatingFieldEnum> {
    const observable = this.getBroadcastById(CommId.ID_ROTATING_FIELD)?.observable;
    if (observable) {
      try {
        const values = await firstValueFrom(observable) as unknown as number[];
        const rotatingField = values[0] as RotatingFieldEnum;
        console.log(
          '[McpDeviceService] >>> readRotatingField rotatingField',
          RotatingFieldEnum[rotatingField]
        );
        return rotatingField;
      } catch (error: any) {
        console.error(
          '[McpDeviceService] >>> readRotatingField failed to read ID ' +
            String(CommId.ID_ROTATING_FIELD) +
            ': ' +
            String(error)
        );
        throw error;
      }
    } else {
      throw new Error('[McpDeviceService] Missing observable for id ' + CommId.ID_ROTATING_FIELD);
    }
    
  }

  private async readIntermediatePositions(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<number[]> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          CommId.ID_INTERMEDIATE_POSITIONS
        );
        console.log(
          '[McpDeviceService] >>> readIntermediatePositions intermediatePositions',
          values
        );
        return values;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] >>> readIntermediatePositions failed to read ID ' +
              String(CommId.ID_INTERMEDIATE_POSITIONS) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readDiagnosisEndPositionFineSettings(): Promise<number[]> {
    const offset =
      (McpDeviceService.END_POSITION_FINE_SETTINGS_MAX -
        McpDeviceService.END_POSITION_FINE_SETTINGS_MIN) /
      2;
    const observable = this.getBroadcastById(CommId.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS)?.observable;
    if (observable) {
      try {
        const result = await firstValueFrom(observable) as unknown as number[];
        const values = result.map((x: number) => x - offset); // 0..2000 => -1000..1000
        console.log(
          '[McpDeviceService] >>> readDiagnosisEndPositionFineSettings endPositionFineSettings',
          values
        );
        return values;
      } catch (error: any) {
        console.error(
          '>>> readDiagnosisEndPositionFineSettings failed to read ID ' +
            String(CommId.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS) +
            ': ' +
            String(error)
        );
        throw error;
      }
    } else {
      throw new Error('[McpDeviceService] >>> readDiagnosisEndPositionFineSettings failed - observable missing.');
    }
    
  }

  private async readCurrentDoorPosition(): Promise<CurrentDoorPositionEnum> {
    try {
      const values = await this.comm.readId(
        CommId.ID_CURRENT_DOOR_POSITION
      );
      const currentDoorPosition = values[0] as CurrentDoorPositionEnum;
      console.log(
        '[McpDeviceService] >>> readCurrentDoorPosition readCurrentDoorPosition',
        CurrentDoorPositionEnum[currentDoorPosition]
      );
      return currentDoorPosition;
    } catch (error: any) {
      console.error(
        '[McpDeviceService] >>> readCurrentDoorPosition failed to read ID ' +
          String(CommId.ID_CURRENT_DOOR_POSITION) +
          ': ' +
          String(error)
      );
      throw error;
    }
  }

  private async writeLearningCommand(
    learningCommand: LearningCommandEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeLearningCommand learningCommand',
      LearningCommandEnum[learningCommand]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_LEARNING_COMMAND,
          learningCommand
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(learningCommand) +
              ' to ID ' +
              String(CommId.ID_LEARNING_COMMAND) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeLearningImePosition(
    learningImePosition: LearningImePositionEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeLearningImePosition learningImePosition',
      LearningImePositionEnum[learningImePosition]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_LEARNING_IME_POSITION,
          learningImePosition
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(learningImePosition) +
              ' to ID ' +
              String(CommId.ID_LEARNING_IME_POSITION) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeEndPositionType(
    endPositionType: EndPositionTypeEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeEndPositionType endPositionType',
      EndPositionTypeEnum[endPositionType]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_END_POSITION_TYPE,
          endPositionType
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(endPositionType) +
              ' to ID ' +
              String(CommId.ID_END_POSITION_TYPE) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeRotatingField(
    rotatingField: RotatingFieldEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeRotatingField rotatingField',
      RotatingFieldEnum[rotatingField]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_ROTATING_FIELD,
          rotatingField
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(rotatingField) +
              ' to ID ' +
              String(CommId.ID_ROTATING_FIELD) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeDeleteImePosition(
    deleteImePosition: DeleteImePositionEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeDeleteImePosition deleteImePosition',
      DeleteImePositionEnum[deleteImePosition]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_DELETE_IME_POSITION,
          deleteImePosition
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(deleteImePosition) +
              ' to ID ' +
              String(CommId.ID_DELETE_IME_POSITION) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeImpulsTarget(
    impulsTarget: ImpulsTargetEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeImpulsTarget impulsTarget',
      ImpulsTargetEnum[impulsTarget]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_IMPULS_TARGET,
          impulsTarget
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(impulsTarget) +
              ' to ID ' +
              String(CommId.ID_IMPULS_TARGET) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeDiagnosisEndPositionFineSettings(
    endPositionFineSettings: number[],
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '[McpDeviceService] >>> writeDiagnosisEndPositionFineSettings endPositionFineSettings',
      endPositionFineSettings
    );

    // convert (and boundary-correct) -200..200 => 0..400
    const offset =
      (McpDeviceService.END_POSITION_FINE_SETTINGS_MAX -
        McpDeviceService.END_POSITION_FINE_SETTINGS_MIN) /
      2;
    endPositionFineSettings = endPositionFineSettings.map((x: number) =>
      Math.min(
        McpDeviceService.END_POSITION_FINE_SETTINGS_MAX,
        Math.max(x + offset, McpDeviceService.END_POSITION_FINE_SETTINGS_MIN)
      )
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          CommId.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS,
          endPositionFineSettings
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '[McpDeviceService] Failed to write ' +
              String(endPositionFineSettings) +
              ' to ID ' +
              String(CommId.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  getBroadcastById(id: number) {
    return this._broadcastService.getBroadcastById(id);
  }

  registerBroadcastsByCategory(category: BroadcastCategory) {
    this._broadcastService.registerBroadcastsByCategory(category);
  }

  unregisterBroadcastsByCategory(category: BroadcastCategory) {
    this._broadcastService.unregisterBroadcastsByCategory(category);
  }
}
