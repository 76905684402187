import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractMcpDeviceService } from '@core/services';
import { ButtonType } from '@shared/components/button/utils';
import { PositionTypeEnum } from '@core/enums';
import { DoorMovement, DoorState } from '@app/shared/enums/enums';
import { RemoteControlService } from './remote-control.service';
import { Subscription } from 'rxjs';
import { CommId } from '@app/core/services/broadcast.service';

@Component({
  selector: 'mcp-remote-control',
  templateUrl: 'remote-control.component.html',
  styleUrls: ['remote-control.component.scss']
})
export class RemoteControlComponent implements OnInit, OnDestroy {
  buttonType = ButtonType.Success;
  positionType = PositionTypeEnum;
  @Input() hasOpenButton = false;
  @Input() hasIntermediateOpenButton = false;
  @Input() hasIntermediateCloseButton = false;
  @Input() hasDeactivationPointOpenButton = false;
  @Input() hasDeactivationPointCloseButton = false;
  @Input() hasLSPointButton = false;
  @Input() hasSafetyEdgeTestPointButton = false;
  @Input() hasCloseButton = false;
  @Input() hasStopButton = false;
  @Input() description: string;
  intermediateOpenedDisabled = false;
  intermediateClosedDisabled = false;
  hasRegistered = false;
  doorState = DoorState.INTERMEDIATE_CLOSED;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly _mcpDevice: AbstractMcpDeviceService,
    private remoteControlService: RemoteControlService
  ) {}

  ngOnInit() {
    if (!this.hasRegistered) {
      try {
        this.hasRegistered = true;
        this.subscriptions.push(
          this.remoteControlService.updateOnDoorPercent.subscribe((values) => {
            const intermediateValues = values as unknown as number[];
            if (Array.isArray(values)) {
              this.intermediateOpenedDisabled = intermediateValues[0] <= 0;
              this.intermediateClosedDisabled = intermediateValues[1] <= 0;
            }
          })
        );
      } catch (error) {
        console.log('[RemoteControl] could not register: ', error);
      }
    }
    this.subscriptions.push(
      this.remoteControlService.updateOnDoorPosition.subscribe(state => {
        this.doorState = state;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }

  moveDoorClickHandler(positionType: PositionTypeEnum) {
    switch (positionType) {
      case PositionTypeEnum.epo:
        this.writeDoorMovement(DoorMovement.END_POSITION_OPEN_INSIDE);
        break;
      case PositionTypeEnum.epc:
        this.writeDoorMovement(DoorMovement.END_POSITION_CLOSE);
        break;
      case PositionTypeEnum.ipo:
        this.writeDoorMovement(DoorMovement.IME_POSITION_OPEN);
        break;
      case PositionTypeEnum.ipc:
        this.writeDoorMovement(DoorMovement.IME_POSITION_CLOSE);
        break;
      case PositionTypeEnum.deactivationPointOpen:
        this.writeData(CommId.ID_SP_DEACTIVATION_OPEN, 0);
        break;
      case PositionTypeEnum.deactivationPointClose:
        this.writeData(CommId.ID_SP_DEACTIVATION_CLOSE, 0);
        break;
      case PositionTypeEnum.lsPoint:
        this.writeData(CommId.ID_LS_POINT, 0);
        break;
      case PositionTypeEnum.safetyEdgeTestPoint:
        this.writeData(CommId.ID_SAFETY_EDGE_TEST_POINT, 0);
        break;
    }
  }

  writeDoorMovement(doorMovement: DoorMovement) {
    const movementIndex = doorMovement as number;
    this.writeData(CommId.ID_IMPULS_TARGET, movementIndex);
  }

  stopClickHandler() {
    this.writeDoorMovement(DoorMovement.POSITION_STOP);
  }

  writeData(id: number, content: number | number[]) {
    this._mcpDevice.clbk_writetoid(id, content);
  }
}
