<ul class="list-group">
  <li
    *ngFor="let item of items"
    class="list-group-item"
    [ngClass]="{'highlighted': item.isHighlighted}"
  >
    <div class="d-flex justify-content-between align-items-center">
      <button
        class="list-group-item__label-button"
        [disabled]="item.isDisabled"
        (click)="itemClickHandler(item)"
      >
        <div class="title">{{ item.label | transloco }}</div>
        <div *ngIf="item.subtitle" class="subtitle">{{ item.subtitle | transloco }}</div>
        <div *ngIf="item.text" class="text">{{ item.text | transloco }}</div>
      </button>

      <ng-container *ngIf="item.selectButtons && item.selectButtons.length > 0">
        <ng-container
          *ngTemplateOutlet="
          selectButtonsTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.statusSignalType">
        <ng-container
          *ngTemplateOutlet="
          statusSignalTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.value && !item.selectOptions">
        <ng-container
          *ngTemplateOutlet="
          valueTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
      

      <button
        *ngIf="item.linkType === 'url'"
        class="list-group-item__action-button"
        [disabled]="item.isDisabled"
      >
        <img [src]="'/assets/icons/chevron-right.svg'" />
      </button>
      <ng-container *ngIf="item.linkType === 'action'">
        <ng-container
          *ngTemplateOutlet="
            actionButtonsTemplate;
            context: { action: item.linkAction, item: item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.selectOptions && item.selectOptions.length > 0">
        <ng-container
          *ngTemplateOutlet="
            dropdownTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="item.sliderParams">
        <ng-container
          *ngTemplateOutlet="
            sliderTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
    </div>
    <div class="elements-small-screen">
      <ng-container *ngIf="item.selectButtons && item.selectButtons.length > 0">
        <ng-container
          *ngTemplateOutlet="
          selectButtonsTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
    </div>
  </li>
</ul>

<ng-template #statusSignalTemplate let-item="item">
  <mcp-status-signal [title]="item.statusSignalTitle" [type]="item.statusSignalType"></mcp-status-signal>
</ng-template>

<ng-template #valueTemplate let-item="item">
  <span class="value">{{item.value}}</span>
</ng-template>

<ng-template #selectButtonsTemplate let-item="item">
  <button *ngFor="let button of item.selectButtons"
    class="list-group-item__select-button"
    [disabled]="item.isDisabled"
    (click)="selectButtonClickHandler(button)"
  >
    {{ button.label | transloco }}
  </button>
</ng-template>

<ng-template #actionButtonsTemplate let-action="action" let-item="item">
  <button
    class="list-group-item__action-button"
    [disabled]="item.isDisabled"
    *ngIf="action === 'add'"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/add-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button-with-text"
    [disabled]="item.isDisabled"
    *ngIf="action === 'execute'"
    (click)="actionClickHandler(item)"
  >
    {{ 'button.execute' | transloco }}
  </button>
  <button
    class="list-group-item__action-button list-group-item__action-button--remove"
    [disabled]="item.isDisabled"
    *ngIf="action === 'remove'"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/close-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button"
    [disabled]="item.isDisabled"
    *ngIf="action === 'info'"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/info-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button"
    [disabled]="item.isDisabled"
    *ngIf="action === 'none'"
  >
  </button>
  <button
    class="list-group-item__action-button"
    [disabled]="item.isDisabled"
    *ngIf="!action"
  >
    <img [src]="'/assets/icons/chevron-right.svg'" />
  </button>
</ng-template>

<ng-template #dropdownTemplate let-item="item">
  <mat-form-field class="dropdown {{selectorSize}}">
    <mat-select matNativeControl
            [(ngModel)]="item.value"
            (selectionChange)="onSelectChange(item)"
            [disabled]="item.disabled"
          >
      <mat-option *ngFor="let option of item.selectOptions" [value]="option.id">
        {{ option.label | transloco }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #sliderTemplate let-item="item">
  <mat-slider
      class="slider"
      [disabled]="item.isDisabled"
      [max]="item.sliderParams.max"
      [min]="item.sliderParams.min"
      [step]="item.sliderParams?.step || 1"
      >
    <input (valueChange)="onDragEnd(item)" matSliderThumb [(ngModel)]="item.value" #slider>
  </mat-slider>
</ng-template>
